import React from 'react'

import { DefaultSeo } from 'next-seo'

import { LocaleTypes } from '@/types/LocaleTypes'

import { getSeoLocale } from '@/utils/locales/getSeoLocale'
import { googleSearchConsoleConfigLocale } from '@/utils/locales/googleSearchConsoleConfigLocale'
import { generateSeoUrl } from '@/utils/seo/generateSeoUrl'

type Props = { locale?: string }

export const DefaultSEO = ({ locale }: Props) => {
	const additionalMetaTagsArr = [
		{
			name: 'og:see_also',
			content: 'https://www.pinterest.com/bigcommerce/',
			keyOverride: 'og:see_also_1'
		},
		{
			name: 'og:see_also',
			content: 'https://www.instagram.com/bigcommerce/',
			keyOverride: 'og:see_also_2'
		},
		{
			name: 'og:see_also',
			content: 'https://www.youtube.com/c/bigcommerce',
			keyOverride: 'og:see_also_3'
		},
		{
			name: 'og:see_also',
			content: 'https://www.linkedin.com/company/bigcommerce',
			keyOverride: 'og:see_also_4'
		},
		{
			name: 'og:see_also',
			content: 'https://www.facebook.com/BigCommerce/',
			keyOverride: 'og:see_also_5'
		},
		{
			name: 'og:see_also',
			content: 'https://twitter.com/BigCommerce',
			keyOverride: 'og:see_also_6'
		},
		{
			name: 'application-name',
			content: 'BigCommerce'
		},
		{
			name: 'msapplication-TileColor',
			content: '#FFFFFF'
		},
		{
			name: 'msapplication-square70x70logo',
			content: '/mm/icons/mstile-70x70.png'
		},
		{
			name: 'msapplication-TileImage',
			content: '/mm/icons/mstile-144x144.png'
		},
		{
			name: 'msapplication-square150x150logo',
			content: '/mm/icons/mstile-150x150.png'
		},
		{
			name: 'msapplication-wide310x150logo',
			content: '/mm/icons/mstile-310x150.png'
		},
		{
			name: 'msapplication-square310x310logo',
			content: '/mm/icons/mstile-310x310.png'
		}
	]

	if (locale === LocaleTypes.en_AU)
		additionalMetaTagsArr.push(
			{
				name: 'og:locale:alternate',
				content: getSeoLocale(LocaleTypes.en_US),
				keyOverride: 'og:locale:alternate_1'
			},
			{
				name: 'og:locale:alternate',
				content: getSeoLocale(LocaleTypes.en_GB),
				keyOverride: 'og:locale:alternate_2'
			}
		)

	const googleMetaConfig =
		locale &&
		googleSearchConsoleConfigLocale[locale as keyof typeof googleSearchConsoleConfigLocale]
	if (googleMetaConfig) additionalMetaTagsArr.push(googleMetaConfig)

	const additionalLinkTags = [
		{
			rel: 'icon',
			href: '/mm/icons/favicon-16x16.png',
			sizes: '16x16',
			type: 'image/png'
		},
		{
			rel: 'icon',
			href: '/mm/icons/favicon-32x32.png',
			sizes: '32x32',
			type: 'image/png'
		},
		{
			rel: 'icon',
			href: '/mm/icons/favicon-96x96.png',
			sizes: '96x96',
			type: 'image/png'
		},
		{
			rel: 'icon',
			href: '/mm/icons/favicon-128x128.png',
			sizes: '128x128',
			type: 'image/png'
		},
		{
			rel: 'icon',
			href: '/mm/icons/favicon-196x196.png',
			sizes: '196x196',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-57x57.png',
			sizes: '57x57',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-60x60.png',
			sizes: '60x60',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-72x72.png',
			sizes: '72x72',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-76x76.png',
			sizes: '76x76',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-114x114.png',
			sizes: '114x114',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-120x120.png',
			sizes: '120x120',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-144x144.png',
			sizes: '144x144',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-152x152.png',
			sizes: '152x152',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-167x167.png',
			sizes: '167x167',
			type: 'image/png'
		},
		{
			rel: 'apple-touch-icon-precomposed',
			href: '/mm/icons/apple-touch-icon-180x180.png',
			sizes: '180x180',
			type: 'image/png'
		}
	]

	return (
		<>
			<DefaultSeo
				canonical={generateSeoUrl('https://www.bigcommerce.com/')}
				openGraph={{
					type: 'website',
					url: generateSeoUrl('https://www.bigcommerce.com/'),
					locale: getSeoLocale(locale),
					site_name: 'BigCommerce'
				}}
				twitter={{
					handle: '@BigCommerce',
					site: '@BigCommerce',
					cardType: 'summary_large_image'
				}}
				additionalMetaTags={additionalMetaTagsArr}
				additionalLinkTags={additionalLinkTags}
			/>
		</>
	)
}
