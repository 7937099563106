globalThis["__sentryRewritesTunnelPath__"] = "/api/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"6775cae60df7ff0aa802fee218418d27db247298"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV ?? process.env.VERCEL_ENV,
  sampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE) ?? 1,
  tracesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) ?? 0,
  profilesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_PROFILES_SAMPLE_RATE) ?? 0,
  debug: false,
  replaysOnErrorSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_ERROR_REPLAY_SAMPLE_RATE) ?? 0,
  replaysSessionSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_REPLAY_SAMPLE_RATE) ?? 0,
  ignoreErrors: ['This Suspense boundary received an update before it finished hydrating', 'Minified React error'],
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.captureConsoleIntegration({levels:['error']}),
    Sentry.contextLinesIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
    }),
    Sentry.sessionTimingIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["www-bc"],
      behaviour: "apply-tag-if-exclusively-contains-third-party-frames",
    }),
    Sentry.rewriteFramesIntegration(),
  ],
});
